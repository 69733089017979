// extracted by mini-css-extract-plugin
export var caption = "customvideo-module--caption--f1db4";
export var container = "customvideo-module--container--00768";
export var headText = "customvideo-module--head-text--8ef71";
export var icon = "customvideo-module--icon--5bbd9";
export var icon1 = "customvideo-module--icon1--984fd";
export var icon2 = "customvideo-module--icon2--41595";
export var icon3 = "customvideo-module--icon3--24c22";
export var icon4 = "customvideo-module--icon4--7981e";
export var icon5 = "customvideo-module--icon5--a33d1";
export var icon6 = "customvideo-module--icon6--469ad";
export var iconSection = "customvideo-module--icon-section--af06d";
export var innerContainer = "customvideo-module--inner-container--1deae";
export var subText = "customvideo-module--sub-text--a83e0";
export var textSection = "customvideo-module--text-section--4ce58";