// extracted by mini-css-extract-plugin
export var container = "process-module--container--20fd5";
export var contentBox = "process-module--content-box--2b2b0";
export var contentBox2 = "process-module--content-box2--38a0c";
export var innerContainer = "process-module--inner-container--68389";
export var processContainer = "process-module--process-container--d1bbe";
export var processSection = "process-module--process-section--e7e23";
export var processStep = "process-module--process-step--5cde3";
export var processStep6 = "process-module--process-step-6--40ef7";
export var processStep7 = "process-module--process-step-7--c014a";
export var processSteps = "process-module--process-steps--03366";
export var rectangleEven = "process-module--rectangle-even--76064";
export var rectangleEven6 = "process-module--rectangle-even-6--a7817";
export var rectangleOdd = "process-module--rectangle-odd--d20fd";
export var rectangleOdd5 = "process-module--rectangle-odd-5--816c9";
export var stepContent = "process-module--step-content--a7658";
export var stepImage = "process-module--step-image--1df1e";
export var stepTitle = "process-module--step-title--56c34";
export var subtitle = "process-module--subtitle--f4bc6";
export var subtitleContainer = "process-module--subtitle-container--0edf1";
export var titlePart1 = "process-module--title-part1--65e8e";
export var titlePart2 = "process-module--title-part2--227b7";
export var titleSection = "process-module--title-section--705cd";